@import "../../styles/components/grid";
@import "../../styles/abstracts/variables";


.userCard {
  @extend %grid-base;
  margin: 0;
  padding: 0.5rem;
  align-items: center;
  border-radius: 5px;
  grid-column: 1 / 13;
}
.username {
  grid-column: 1 / 3;
}
.email {
  grid-column: 3 / 6;
}
.role {
  grid-column: 6 / 7;
}
.country {
  grid-column: 7 / 8;
}
.status {
  grid-column: 9 / 11;
}
.statusButton {
  grid-column: 9 / 10;
}
.editButton {
  grid-column: 11 / 12;
}
.deleteButton {
  grid-column: 12 / 13;
}
.deleteConfirmation {
  padding: 2rem;
}