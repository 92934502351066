@import '../../styles/components/grid';
@import '../../styles/components/inputs';
@import '../../styles/components/titles';
@import '../../styles/components/paper';

@import '../../styles/abstracts/variables';

.landingPage {
  @extend %grid-base;
  gap: 10px;
}
.landingTitle {
  grid-column: 1 / 5;
  @extend %title-medium-primary;
  font-size: $text-large;
}
.contentWrapper {
  grid-column: 1 / 6;
  grid-row: 2 / 4;
}
.introText {
  font-size: $text-extrasmall;
  font-weight: 500;
  margin: 0 0 1rem 0;
}
.CTAWrapper {
  display: flex;
}
.scheme {
  grid-column: 7 / 13;
  grid-row: 1 / 5;
  justify-self: end;
}
.modalWrapper {
  background-color: $color-third;
  display: flex;
  flex-flow: column nowrap;
}
.modal {
  background-color: $color-third;
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem 2rem 2rem 2rem;
}
.closeButtonWrapper {
  align-self: flex-end;
}
.closeButton {
  
  width: 70px;
  height: 70px;
}
.logos {
  background-color: #fff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1 / 5;
  grid-row: 4 / 5;
  padding: 1rem;
  place-items: center;
}
.euroWrapper {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}
.disclaimer {
  text-align: center;
  color: #000;
}