@import '../abstracts/variables';

/* PRIMARY TITLES */

/* PRIMARY LARGE */
%title-large-primary {
  color: $color-primary;
  font-weight: 900;
  font-size: $text-extralarge;
  margin: 0;
}

/* PRIMARY MEDIUM */
%title-medium-primary {
  color: $color-primary;
  font-weight: 700;
  font-size: $text-small;
  margin: 0;
}

/* PRIMARY SMALL */
%title-small-primary {
  color: $color-primary;
  font-weight: 500;
  font-size: $text-extrasmall;
  margin: 0;
}
/* SECONDARY TITLES */

/* SECONDARY LARGE */
%title-large-secondary {
  color: $color-secondary;
  font-weight: 900;
  font-size: $text-medium;
  margin: 0;
}

/* SECONDARY MEDIUM */
%title-medium-secondary {
  color: $color-secondary;
  font-weight: 700;
  font-size: $text-small;
  margin: 0;
}

/* SECONDARY SMALL */
%title-small-secondary {
  color: $color-secondary;
  font-weight: 500;
  font-size: $text-extrasmall;
  margin: 0;
}
