@import '../../../styles/components/grid';
@import '../../../styles/components/inputs';
@import '../../../styles/components/titles';
.mobileHomeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.desktopJoinWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.mobileHome {
  @extend %grid-base;
  gap: 10px 0;
  border-radius: 5px;
  width: 70%;
}
.gameTitle {
  justify-self: center;
  grid-column: 1 / 13;
  @extend %title-medium-primary;
}
.codeInput {
  grid-column: 1 / 13;
}
.joinGameButton {
  grid-column: 1 / 13;
}
.changeLang {
  grid-column: 1 / 13;
  place-self: center;
}
