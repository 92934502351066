@import "../../../../styles/abstracts/variables";
@import "../../../../styles/components/titles";
@import "../../../../styles/components/grid";
@import "../../../../styles/components/paper";

.projector {
	display: flex;
	flex-flow: column nowrap;
}
.codeOnProjector {
	@extend %title-medium-primary;
	text-align: center;
}
.graphWrapper {
	align-self: center;
	width: 95%;
}
.tweet {
	
	@extend %grid-base;
	@extend %paper-base;
	gap: 5px;
	width: 50%;
	align-self: center;
}
.tweetLogo {
	grid-column: 1 / 2;
	grid-row: 1 / 3;
}
.tweetUsername {
	@extend %title-small-primary;
	color: #fff;
	align-self: end;
	grid-column: 2 / 13;
	grid-row: 1 / 2;

}
.tweetTag {
	grid-column: 2 / 13;
	grid-row: 2 / 3;
	align-self: start;
	color: rgb(177, 177, 177);
}
.news {
    grid-column: 1 / 13;
	font-size: 200%;
}
.tweetDate {
	color: rgb(177, 177, 177);
	grid-column: 1 / 13;
}