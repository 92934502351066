@import "../abstracts/variables";
%button-base {
	background-color: $color-primary;
	color: white;
	&:hover {
		cursor: pointer;
	}
}
.button-large {
	@extend %button-base;
	padding: 2.5rem 1.5rem;
	font-size: 2rem;
}