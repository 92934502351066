@import '../../../../styles/abstracts/variables';
@import '../../../../styles/components/titles';

.reportBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportTitle {
  @extend %title-small-primary;
}
.closeButton {
  width: 70px;
  height: 70px;
}
.subtitle {
  color: $color-primary;
}
table {
  margin: 0;
  border-spacing: 0;
}
tbody tr {
  background: $color-third;
}
tbody tr:nth-child(odd) {
  background: #0c643b;
}
table td,
table th {
  color: white;
  padding: 1rem 1.5rem;
}
table tbody th {
  text-align: left;
  font-weight: 100;
  position: sticky;
  left: 0;
  z-index: 1;
  background: inherit;
}
