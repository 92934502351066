@import '../../styles/components/grid';
@import '../../styles/components/inputs';
@import '../../styles/components/titles';
@import '../../styles/components/paper';

.homeWrapper {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
.home {
  @extend %paper-base;
  margin: auto 10rem;
  @extend %grid-base;
  gap: 0.5rem;
}
.welcomeMessage {
  @extend %title-medium-primary;
  grid-column: 1 / 13;
  grid-row: 1 / 2;
  place-self: center;
  color: white;
}
.activeGamesModule {
  display: flex;
  flex-flow: row nowrap;
  grid-column: 1 / 13;  
  grid-row: 2 / 3;
}
.activeGames {
  background: none;
  font-size: $text-extrasmall;
  padding: 0;
  height: 2rem;
  margin: 0 1rem;
  &:hover {
    color: #FCB005;
    cursor: pointer;
  }
}
.buttonsWrapper {
  display: flex;
  grid-column: 1 / 13;
  place-self: center;
  width: 100%;
  justify-content: space-between;
}
.langButton {
  grid-column: 12 / 13;
  grid-row: 1 / 2;
}
@media only screen and (min-width: 1280px) {
  .home {
    margin: auto 20rem;
  }
}
@media only screen and (min-width: 1920px) {
  .home {
    margin: auto 35rem;
  }
}
