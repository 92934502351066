@import '../../../../styles/components/grid';
@import '../../../../styles/components/paper';
@import '../../../../styles/components/titles';

.endScreenModule {
  @extend %grid-base;
}
.top3 {
  @extend %paper-base;
  @extend %grid-base;
  height: 30rem;
  border: 0;
  margin: 0;
  gap: 0;
  grid-column: 1 / 13;
  grid-row: 2 / 6;
}
.graphHost {
  grid-column: 1 / 13;
  grid-row: 2 / 6;
  width: 60%;
  place-self: center;
}
.projectorWrapper {
  display: flex;
  flex-flow: column nowrap;
}
.graphTitle {
  @extend %title-medium-primary;
  text-align: center;
}
.graphProjector {
  align-self: center;
  width: 95%;
}
.gameOver {
  @extend %title-medium-primary;
  grid-column: 5 / 9;
  place-self: center;
}
.money {
  background-color: #85bb65;
  margin-top: 0.5rem;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 0.5rem;
}
.winner {
  grid-column: 5 / 9;
  grid-row: 2 / 3;
  place-self: end center;
  font-size: 1.5rem;
}
.gold {
  display: flex;
  align-items: center;
  flex-flow: column;
  grid-column: 5 / 9;
  grid-row: 3 / 8;
  background-color: #ffd700;
  border-radius: 5px;
  margin: 0 5rem;
}
.second {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  font-size: 1.5rem;
  place-self: end center;
}
.silver {
  display: flex;
  align-items: center;
  flex-flow: column;
  grid-column: 1 / 4;
  grid-row: 4 / 8;
  background-color: #e5e5e5;
  border-radius: 5px;
  margin: 0 4rem;
}
.third {
  grid-column: 10 / 13;
  grid-row: 5 / 6;
  font-size: 1.5rem;
  place-self: end center;
}

.bronze {
  display: flex;
  align-items: center;
  flex-flow: column;
  grid-column: 10 / 13;
  grid-row: 6 / 8;
  background-color: #cd7f32;
  margin: 0 4rem;
  border-radius: 5px;
}
.buttons {
  justify-self: end;
  grid-column: 6 / 13;
  grid-row: 6 / 8;
}
.graphControls {
  justify-self: start;
  grid-column: 1 / 7;
  grid-row: 6 / 7;
}
