@import '../../../../styles/components/titles';
@import '../../../../styles/components/paper';
.wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.endScreenPlayer {
  @extend %paper-base;
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
}
.placementTitle {
	@extend %title-large-primary;
	text-align: center;
}
.placementText {
  @extend %title-small-primary;
  margin: 1rem 0;
}
