@import "../styles/components/titles";
@import "../styles/abstracts/variables";


.modal {
	display: flex;
	flex-flow: column nowrap;
	background-color: $color-third;
	width: 15rem;
	padding: 0 0 1rem 1rem;
}
.errorMessageTitle {
	@extend %title-medium-primary;
}

.closeButton {
	width: 50px;
	height: 50px;
	align-self: end;
	&:hover {
		cursor: pointer;
	}
}