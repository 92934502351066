@import '../../../styles/components/grid';
@import '../../../styles/components/paper';
@import '../../../styles/components/titles';
@import '../../../styles/abstracts/variables';



.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.component {
    
    @extend %paper-base;
    @extend %grid-base;
    grid-template-columns: 1fr;
}
.title {
    @extend %title-medium-primary;
    
}
.message {
    font-size: $text-small;
}
