@import '../../../../styles/components/grid';
@import '../../../../styles/components/titles';
@import '../../../../styles/abstracts/variables';
@import '../../../../styles/components/paper';

.hostView {
  @extend %grid-base;
}
.eventsModule {
  @extend %paper-base;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}
.eventsTitle {
  @extend %title-medium-primary;
}
.userListModule {
  @extend %paper-base;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 3rem repeat(15, 2rem);
  grid-column: 4 / 13;
  grid-row: 1 / 5;
  font-size: $text-extrasmall;
}
.playersTitle {
  @extend %title-medium-primary;
  grid-row: 1 / 2;
  grid-column: 1 / 6;
  justify-self: start;
}
.customEventBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.closeButton {
  width: 70px;
  height: 70px;
}
.users {
  justify-self: start;
  background: none;
  font-size: $text-extrasmall;
  padding: 0;
  height: 2rem;
}
.userDisconnected {
  cursor: pointer;
  opacity: 0.5;
}
.userAnswered {
  cursor: pointer;
  color: #0f0;
}
.userTooltip {
  padding: 0.5rem;
}
.controlButtons {
  place-self: end;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  grid-column: 1 / 13;
  grid-row: 5 / 6;
}
.deleteConfirmation {
  padding: 2rem;
}
.graphControls {
  @extend %paper-base;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: start;
  align-items: flex-start;
  grid-gap: 0.5rem;
  grid-column: 1 / 4;
  grid-row: 2 / 5;
}
.graphControlsTitle {
  @extend %title-medium-primary;
  grid-column: 1 / 3;
}
.difficultyWrapper {
  justify-self: start;
  align-self: end;
  grid-column: 1 / 6;
  grid-row: 5 / 6;
}
