@charset "UTF-8";
@import './abstracts/variables';
@import './components/buttons';
@import './components/titles';
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
}
body {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-third;
  box-sizing: border-box;
}
div {
  box-sizing: border-box;
}
p,
span,
h1,
h2,
h3 {
  color: #fff;
}
h1,
h2,
h3 {
  text-align: center;
}
input {
  border-radius: 5px;
  border: none;
}
html {
  height: 100%;

  box-sizing: border-box;
}
button {
  font-family: 'Roboto';
  text-decoration: none;
  border: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  border-radius: 5px;
  color: #fff;
}
