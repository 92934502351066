@import "../../styles/abstracts/variables";
@import "../../styles/components/titles";
@import "../../styles/components/paper";
@import "../../styles/components/grid";

.WaitingScreen {
	@extend %grid-base;
}
.userModule {
	@extend %paper-base;
	display: grid;
	grid-column: 1 / 7;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
}
.playerListTitle {
	@extend %title-medium-primary;
	grid-column: 1 / 2;
	justify-self: start;
}
.gameCodeHost {
	@extend %title-medium-primary;
	grid-column: 3 / 4;
	justify-self: start;
}
.controls {
	grid-column: 1 / 6;
	display: flex;
}
.users {
	justify-self: start;
	background: none;
	font-size: $text-extrasmall;
	padding: 0;
	height: 2rem;
	&:hover {
		text-decoration: line-through;
		text-decoration-color: #F44336;
	}
}
/* PROJECTOR */
.centeringWrapper {
	display: flex;
	flex-flow: column;
	height: 100vh;
  }
.WaitingScreenProjector {
	@extend %grid-base;
	margin: auto;
	@extend %paper-base;
	padding: 1rem;
}
.gameCodeLabel {
	grid-column: 1 / 13;
	grid-row: 1 / 2;
	
	place-self: start center;
	font-size: $text-extrasmall;
}
.gameCode {
	@extend %title-large-primary;
	grid-column: 1 / 13;
	place-self: center;
	grid-row: 1 / 2;
}
.firstNames {
	height: 100%;
	font-size: $text-small;
	grid-column: 1 / 13;
	grid-row: 2 / 3;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 3rem;
	place-items: center;
}
.firstNames > span {
	padding: 10px;
	border-radius: 5px;
	background: $color-primary;
}
.connected {
	color: $color-primary;
	border-radius: 5px;
	margin: 0;
	place-self: start center;
	padding: 10px;
	font-size: $text-small;
	grid-column: 12 / 13;
	grid-row: 1 / 2;
}
