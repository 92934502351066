@import '../../styles/components/grid';
@import '../../styles/components/paper';
@import '../../styles/components/titles';
.admin {
  @extend %grid-base;
  margin: 0;
}
.search {
  grid-column: 6 / 13;
  display: flex;
  align-content: center;
  justify-content: flex-end;
}
.userCardWrapper {
  @extend %grid-base;
  @extend %paper-base;
  grid-column: 1 / 13;
  gap: 0;
}
.acceptAllChanges {
  grid-row: 2 / 3;
  grid-column: 6 / 9;
}
.userCardWrapper > div:nth-child(odd) {
  background-color: #0c643b;
}
.usersListTitle {
  grid-column: 1 / 2;
  @extend %title-medium-primary;
}
.homeButtonWrapper {
  place-self: center;
  grid-column: 1 / 13;
  grid-row: 1 / 2;
  justify-self: start;
  margin-bottom: 1rem;
}
.labelsWrapper {
  @extend %grid-base;
  grid-column: 1 / 13;
  margin: 0;
  padding: 0.5rem;
  align-items: end;
}
.usernameLabel {
  @extend %title-small-primary;
  grid-column: 1 / 3;
}
.emailLabel {
  @extend %title-small-primary;
  grid-column: 3 / 6;
}
.roleLabel {
  @extend %title-small-primary;
  grid-column: 6 / 7;
}
.countryLabel {
  @extend %title-small-primary;
  grid-column: 7 / 9;
}
.statusLabel {
  @extend %title-small-primary;
  grid-column: 9 / 11;
}
