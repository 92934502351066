@import '../../../../styles/components/grid';
@import '../../../../styles/components/titles';

.mobileNameScreenWrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.desktopNameScreen {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
.mobileNameScreen {
  @extend %grid-base;
  gap: 10px 0;
  margin: auto;
  width: 70%;
}
.nameTitle {
  grid-column: 1 / 13;
  justify-self: center;
  @extend %title-medium-primary;
}
.nameInput {
  grid-column: 1 / 13;
}
.confirmNameButton {
  grid-column: 1 / 13;
}
