@import '../../../../styles/components/grid';
@import '../../../../styles/components/titles';

.playerViewGrid {
  @extend %grid-base;
  gap: 2rem 0;
}
.nameAndWeek {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-top: 2rem;
  grid-column: 1 / 13;
  place-self: center;
}
.weekTitle {
  @extend %title-medium-primary;
}
.changeLang {
  grid-column: 12 / 13;
  place-self: start;
}
.openModalButton {
  grid-column: 1 / 13;
}
.balanceInput {
  grid-column: 1 / 13;
}
.stockInput {
  grid-column: 1 / 13;
}
.resellPrice {
  grid-column: 1 / 13;
}
.amountToBuy {
  grid-column: 1 / 13;
}
.advertisementInput {
  grid-column: 1 / 13;
}
.sendGameData {
  grid-column: 1 / 13;
}
.newWeekAlert {
  padding: 2rem;
}
.dialogText {
  @extend %title-medium-primary;
  text-align: center;
}
